import { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'
import { slugifyString } from 'shared-utilities'
import Seo from '../../../components/seo'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs'
import MainTitle from '../../../components/MainTitle/MainTitle'
import PermaLink from '../../../components/PermaLink/PermaLink'
import MarkdownText from '../../../components/MarkdownText/MarkdownText'
import LinkComponent from '../../../components/LinkComponent/LinkComponent'
import {
  glossaryData,
  externalGlossaryLinks,
} from '../../../content/qml/glossary/externalGlossaryLinks'

const { meta_title, meta_description, meta_image } = glossaryData.meta

export default function GlossaryPage({
  data,
}: PageProps<Queries.GetGlossaryHomePageQuery>) {
  const glossaryItems = data.allMdx.edges

  const sortedGlossaryEntries = useMemo(() => {
    const formattedQueryItems = glossaryItems.map((item) => ({
      title: item.node.frontmatter?.title || '',
      href: item?.node.frontmatter?.slug
        ? `/qml/glossary/${slugifyString(item?.node.frontmatter?.slug)}`
        : '',
      description: item.node.frontmatter?.description || '',
    }))

    return [...formattedQueryItems, ...externalGlossaryLinks].sort((a, b) =>
      a.title.localeCompare(b.title)
    )
  }, [glossaryItems, externalGlossaryLinks])

  return (
    <>
      <Breadcrumbs pageTitle={glossaryData.title} />
      <section className="py-[140px] lg:py-[120px] pb-2">
        <div className="section-xs">
          <MainTitle title={glossaryData.title} />
          <ul className="pl-0 mb-16">
            {sortedGlossaryEntries.map(({ title, description, href }, i) => (
              <li className="list-none" key={`glossary-item-${i}`}>
                <h2>
                  <LinkComponent
                    href={href}
                    className="text-lg font-black text-info-1 hover:text-primary-blue-4 focus:text-primary-blue-4 transition-all duration-300"
                  >
                    {title}
                  </LinkComponent>
                  <PermaLink title={title} className="text-lg" />
                </h2>
                <MarkdownText text={description} className="pl-8" />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  )
}

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}

export const pageQuery = graphql`
  query GetGlossaryHomePage {
    allMdx(
      filter: { internal: { contentFilePath: { regex: "/content/glossary/" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          id
          internal {
            contentFilePath
          }
          frontmatter {
            slug
            title
            description
          }
        }
      }
    }
  }
`
