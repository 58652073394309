export const glossaryData = {
  title: 'Glossary',
  meta: {
    meta_title: 'Glossary | PennyLane',
    meta_description:
      'A growing glossary of key concepts related to quantum computing, quantum machine learning, and quantum chemistry.',
    meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/glossary/board_open_graph.png',
  },
}


export const externalGlossaryLinks = [
  {
    id: 'tutorial_local_cost_functions',
    slug: '',
    title: 'Barren Plateaus',
    description:
      'Areas in the cost landscape where the gradient of a parameterized circuit disappears. The mortal enemy of many a variational algorithm, the variance of the gradient at these points is also close to zero in all directions.',
    href: '/qml/demos/tutorial_local_cost_functions',
  },
  {
      id: 'tutorial_qaoa_intro',
      slug: 'tutorial_qaoa_intro',
      title: 'Quantum Approximate Optimization Algorithm (QAOA)',
      description:
        'A hybrid variational algorithm that is used to find approximate solutions for combinatorial optimization problems. Characterized by a circuit ansatz featuring two alternating parameterized components.',
      href: '/qml/demos/tutorial_qaoa_intro',
    },
    {
      id: 'tutorial_QGAN',
      slug: 'tutorial_QGAN',
      title: 'Quantum Generative Adversarial Network',
      description:
        'Quantum analog of [Generative Adversarial Networks](https://en.wikipedia.org/wiki/Generative_adversarial_network) (GANs).',
      href: '/qml/demos/tutorial_QGAN',
    },
    {
      id: 'tutorial_quanvolution',
      slug: 'tutorial_quanvolution',
      title: 'Quanvolutional Neural Network',
      description:
        'A hybrid classical-quantum model in which classical CNNs are augmented by layers of variational quantum circuits.',
      href: '/qml/demos/tutorial_quanvolution',
    },
    {
      id: 'tutorial_variational_classifier',
      slug: 'tutorial_variational_classifier',
      title: 'Variational Quantum Classifier (VQC)',
      description: `A supervised learning algorithm in which variational circuits QNNs are trained to perform classification tasks.`,
      href: '/qml/demos/tutorial_variational_classifier',
    },
    {
      id: 'tutorial_vqe',
      slug: 'tutorial_vqe',
      title: 'Variational Quantum Eigensolver (VQE)',
      description:
        'A variational algorithm used for finding the ground-state energy of a quantum system. The VQE is a hybrid algorithm that involves incorporating measurement results obtained from a quantum computer running a series of variational circuits into a classical optimization routine in order to find a set of optimal variational parameters.',
      href: '/qml/demos/tutorial_vqe',
    },
    {
      id: 'tutorial_vqls',
      slug: 'tutorial_vqls',
      title: 'Variational Quantum Linear Solver (VQLS)',
      description:
        'An algorithm for solving systems of linear equations on quantum computers. Based on short variational circuits, it is amenable to running on near-term quantum hardware.',
      href: '/qml/demos/tutorial_vqls',
    },
    {
      id: 'tutorial_vqt',
      slug: 'tutorial_vqt',
      title: 'Variational Quantum Thermalizer (VQT)',
      description: `A generalization of the VQE to systems with non-zero temperatures. Uses QHBMs to generate thermal states of Hamiltonians at a given temperature.`,
      href: '/qml/demos/tutorial_vqt',
    },
    {
      id: 'whatisqml',
      slug: 'whatisqml',
      title: 'Quantum Machine Learning',
      description: `A research area that explores ideas at the intersection of machine learning and quantum computing.`,
      href: '/qml/whatisqml',
  },
  {
    id: 'what-is-quantum-chemistry',
    slug: 'what-is-quantum-chemistry',
    title: 'Quantum Chemistry',
    description: `A research area focused on addressing classically intractable chemistry problems with quantum computing.`,
    href: '/qml/what-is-quantum-chemistry',
  },
  {
    id: 'what-is-quantum-computing',
    slug: 'what-is-quantum-computing',
    title: 'Quantum Computing',
    description: `A research area that extends the set of physical laws classical computers operate on by accessing quantum aspects of the physical world, opening up new ways of processing information.`,
    href: '/qml/what-is-quantum-computing',
  },
  {
    id: 'datasets',
    slug: 'datasets',
    title: 'Quantum Datasets',
    description: `Collections of data for physical systems that exhibit quantum behavior.`,
    href: '/datasets',
  },
]
